@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html { scroll-behavior: smooth; }

body{
    font-family: 'Roboto', sans-serif;
}

.animatedArrow
{
    position: absolute; 
    bottom: 25px; 
    right: 50%; 
    left: 50%;
    z-index: 1;

    animation-duration: 2s;
    animation-name: toandfro;
    animation-iteration-count:infinite;
}


@keyframes toandfro {
    from {
        bottom: 35px;
    }
    75% {
        bottom: 0px;
    }
    to {
        bottom: 25px;
    }
}