.containerInside
{
    position: relative;
    height: 400px;
    border: 1px solid rgb(197, 197, 197);
    box-shadow: 1px 1px 0px 0.5px grey;
    border-radius: 2px 2px 2px 2px;
}
.image
{
    display: block;
    width: 100%;
    height: 100%;
}
.imageOverlay
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-family: 'Times New Roman', Times, serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.imageOverlayBlur{
    backdrop-filter: blur(5px);
}

.imageOverlay > * {
    transform: translateX(-100px);
    transition: transform 0.7s;
}

.imageOverlay:hover > * {
    transform: translateX(0px);
}

.imageOverlay:hover{
    opacity: 0.9;
}

.image_title
{
    font-size: 2em;
    font-weight: 500;
    text-align: center;
    text-decoration: underline;
}
.imageDisc
{
    font-size: 1.25em;
    margin-top: 0.25em;
}